/**
 * 校验中国大陆手机号
 * @param phoneNumber - 需要校验的手机号
 * @returns 是否为有效的手机号
 */
export function validatePhoneNumber(phoneNumber: string): boolean {
    // 全球手机号正则表达式
    const phoneRegex = /^(?:\+?\d{1,3})?[0-9]{10,}$/;
    return phoneRegex.test(phoneNumber);
}



export function getMarkDownHeadings(markdown: string): Heading[] {
    const headingRegex = /^(#+\s)([^#][^=].*?(?=\n[^#]|\n\s*#{1,6}\s|$))/gs;
    const linkRegex = /\[([^\]]+)\]\([^\)]+\)/g;
    let match;
    const headings: Heading[] = [];

    while ((match = headingRegex.exec(markdown))) {
        let title = match[2].replace(linkRegex, (match, p1) => p1);

        headings.push({ level: match[1].length, title: title });
    }

    return headings;
}


export function image(url: string, size?: number) {
    if (!size) return url;
    return `${url}?x-oss-process=image/resize,w_${size}`
}

export function oss(url: string) {
    return `${import.meta.env.VITE_OSS_URL}/${url}`
}

/**
 * 获取网站子域名
 * @returns 
 */
export function getWebsite() {
    const host = window.location.host;
    const website = host.split('.')[0];
    return website;
}


/**
 * 判断是在iframe中
 */
export function isIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
/**
 * 判断是否电脑端
 */
export function isPC() {
    const userAgentInfo = navigator.userAgent;
    const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
        }
    }
    return flag;
}

/**
 * 节流函数
 * @param func 
 * @param wait 
 * @returns 
 */
// export function throttle(func: Function, wait: number) {
//     let previous = 0;
//     return function (this: any) {
//         let now = Date.now();
//         if (now - previous > wait) {
//             func.apply(this, arguments);
//             previous = now;
//         }
//     };
// }

export function throttle<T extends (...args: any[]) => void>(func: T, limit: number) {
    let lastFunc: ReturnType<typeof setTimeout>;
    let lastRan: number;

    return function (this: any, ...args: Parameters<T>) {
        const context = this;

        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}
/**
 * 防抖函数
 * @param func 
 * @param wait 
 * @returns 
 */
export function debounce(func: Function, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (this: any) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, arguments);
        }, wait);
    };
}
export const compressImage = (imageUrl: string, maxWidth: number = 100, maxHeight: number = 100): Promise<Blob | null> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const MAX_WIDTH = maxWidth; // 设置最大宽度
            const MAX_HEIGHT = maxHeight; // 设置最大高度
            let width = img.width;
            let height = img.height;

            // 计算新的宽高比
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(blob => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        console.error('Failed to convert canvas to blob.');
                        resolve(null);
                    }
                })

            } else {
                console.error('Failed to get the 2D drawing context for the canvas.');
                resolve(null);
            }
        };
    });
};

/**
 * 
 * @returns 获取子域名
 */
export function getSubdomain() {
    const hostname = window.location.hostname;

    // 分割hostname得到各个部分，例如 "sub.example.com" => ["sub", "example", "com"]
    const parts = hostname.split('.');

    // 检查是否至少有3个部分（子域名、主域名、顶级域名）
    return parts[0]
}