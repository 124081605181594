export const LogoSvg = ({ color = "black" }: { color: "black" | "white" }) => {
  const light = "rgba(255,255,255,.8)";
  return (
    <svg
      fill="none"
      width="100%"
      height="100%"
      viewBox="0 0 541 355"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={color === "white" ? "#565656" : light}
          d="M 132.5,-0.5 C 133.833,-0.5 135.167,-0.5 136.5,-0.5C 185.616,15.8733 234.616,32.8733 283.5,50.5C 283.833,52 284.167,53.5 284.5,55C 283.848,126.492 283.181,197.992 282.5,269.5C 266.588,278.956 250.255,287.623 233.5,295.5C 227.823,293.245 222.323,290.578 217,287.5C 215.727,249.509 214.894,211.509 214.5,173.5C 219.785,165.256 223.118,156.256 224.5,146.5C 224.406,144.077 223.906,141.744 223,139.5C 220.604,136.933 218.104,134.6 215.5,132.5C 216.637,116.211 216.804,99.8775 216,83.5C 215.884,74.8757 215.051,66.3757 213.5,58C 184.932,49.8164 156.099,42.6497 127,36.5C 110.766,43.1349 94.5988,49.9683 78.5,57C 75.5682,58.1449 72.5682,58.9782 69.5,59.5C 70.4735,135.164 70.6401,210.831 70,286.5C 58.9975,293.418 47.4975,299.418 35.5,304.5C 23.495,299.499 11.495,294.499 -0.5,289.5C -0.5,216.5 -0.5,143.5 -0.5,70.5C 0.166602,63.6553 0.833269,56.6553 1.5,49.5C 45.2657,32.805 88.9324,16.1383 132.5,-0.5 Z M 154.5,140.5 C 157.167,140.5 159.833,140.5 162.5,140.5C 163.243,146.703 160.743,148.703 155,146.5C 154.505,144.527 154.338,142.527 154.5,140.5 Z M 102.5,142.5 C 105.877,142.959 109.211,143.626 112.5,144.5C 110.433,147.714 107.433,149.714 103.5,150.5C 102.609,149.739 101.942,148.739 101.5,147.5C 102.046,145.865 102.38,144.199 102.5,142.5 Z"
        />
      </g>
      <g>
        <path
          fill={color === "white" ? "#000" : "#1f1d25"}
          d="M 215.5,132.5 C 212.421,134.467 209.588,136.8 207,139.5C 206.233,150.867 206.399,162.2 207.5,173.5C 209.833,173.5 212.167,173.5 214.5,173.5C 214.894,211.509 215.727,249.509 217,287.5C 222.323,290.578 227.823,293.245 233.5,295.5C 233.047,296.458 232.381,297.292 231.5,298C 197.506,317.168 163.172,335.668 128.5,353.5C 127.833,353.5 127.167,353.5 126.5,353.5C 96.039,337.436 65.7057,321.102 35.5,304.5C 47.4975,299.418 58.9975,293.418 70,286.5C 70.6401,210.831 70.4735,135.164 69.5,59.5C 72.5682,58.9782 75.5682,58.1449 78.5,57C 94.5988,49.9683 110.766,43.1349 127,36.5C 156.099,42.6497 184.932,49.8164 213.5,58C 215.051,66.3757 215.884,74.8757 216,83.5C 216.804,99.8775 216.637,116.211 215.5,132.5 Z M 154.5,140.5 C 157.167,140.5 159.833,140.5 162.5,140.5C 163.243,146.703 160.743,148.703 155,146.5C 154.505,144.527 154.338,142.527 154.5,140.5 Z M 102.5,142.5 C 105.877,142.959 109.211,143.626 112.5,144.5C 110.433,147.714 107.433,149.714 103.5,150.5C 102.609,149.739 101.942,148.739 101.5,147.5C 102.046,145.865 102.38,144.199 102.5,142.5 Z"
        />
      </g>
      <g>
        <path
          fill="#e7b594"
          d="M 188.5,78.5 C 190.284,79.5738 191.451,81.2404 192,83.5C 192.677,106.362 193.844,129.195 195.5,152C 194.39,169.604 192.39,187.104 189.5,204.5C 186.984,204.245 184.65,203.411 182.5,202C 169.366,192.674 156.866,182.507 145,171.5C 139.431,175.037 133.931,178.703 128.5,182.5C 124.022,179.19 119.689,175.69 115.5,172C 113.956,170.813 112.289,170.48 110.5,171C 112.325,172.466 112.825,174.299 112,176.5C 106.866,180.633 102.032,185.133 97.5,190C 93.3612,192.639 89.3612,195.472 85.5,198.5C 74.8678,176.004 70.5344,152.337 72.5,127.5C 72.345,112.124 73.0116,96.7902 74.5,81.5C 91.893,88.6969 108.893,96.6969 125.5,105.5C 146.706,96.7239 167.706,87.7239 188.5,78.5 Z M 154.5,140.5 C 157.167,140.5 159.833,140.5 162.5,140.5C 163.243,146.703 160.743,148.703 155,146.5C 154.505,144.527 154.338,142.527 154.5,140.5 Z M 102.5,142.5 C 105.877,142.959 109.211,143.626 112.5,144.5C 110.433,147.714 107.433,149.714 103.5,150.5C 102.609,149.739 101.942,148.739 101.5,147.5C 102.046,145.865 102.38,144.199 102.5,142.5 Z"
        />
      </g>
      <g>
        <path
          fill="#232027"
          d="M 169.5,116.5 C 172.649,116.211 175.649,116.711 178.5,118C 177.299,118.903 176.465,120.069 176,121.5C 164.938,127.291 154.771,134.458 145.5,143C 139.969,142.197 135.302,139.53 131.5,135C 143.86,127.986 156.526,121.819 169.5,116.5 Z"
        />
      </g>
      <g>
        <path
          fill="#201e26"
          d="M 112.5,144.5 C 109.211,143.626 105.877,142.959 102.5,142.5C 99.4115,141.215 96.0781,140.549 92.5,140.5C 93.172,139.733 94.0053,139.066 95,138.5C 95.6667,137.833 95.6667,137.167 95,136.5C 88.7398,132.244 83.0731,127.244 78,121.5C 77.4092,120.517 77.7425,119.85 79,119.5C 92.8489,123.777 105.849,129.777 118,137.5C 119.38,142.291 117.547,144.624 112.5,144.5 Z"
        />
      </g>
      <g>
        <path
          fill={color == "black" ? light : "#111111"}
          d="M 540.5,132.5 C 540.5,134.833 540.5,137.167 540.5,139.5C 539.694,143.002 539.027,146.669 538.5,150.5C 538.182,151.647 537.516,152.481 536.5,153C 516.836,153.5 497.169,153.667 477.5,153.5C 477.5,154.5 477.5,155.5 477.5,156.5C 496.167,156.5 514.833,156.5 533.5,156.5C 533.057,163.783 532.057,170.95 530.5,178C 479.168,178.5 427.834,178.667 376.5,178.5C 376.665,171.183 377.665,164.016 379.5,157C 398.164,156.5 416.83,156.333 435.5,156.5C 435.5,155.5 435.5,154.5 435.5,153.5C 416.164,153.667 396.83,153.5 377.5,153C 376.833,152.333 376.167,151.667 375.5,151C 376.873,144.928 377.873,138.761 378.5,132.5C 398.5,132.5 418.5,132.5 438.5,132.5C 438.66,130.801 438.494,129.134 438,127.5C 435.453,125.796 433.953,123.463 433.5,120.5C 449.833,120.5 466.167,120.5 482.5,120.5C 481.838,124.466 481.505,128.466 481.5,132.5C 501.167,132.5 520.833,132.5 540.5,132.5 Z"
        />
      </g>
      <g>
        <path
          fill="#e6b493"
          d="M 215.5,132.5 C 218.104,134.6 220.604,136.933 223,139.5C 223.906,141.744 224.406,144.077 224.5,146.5C 223.118,156.256 219.785,165.256 214.5,173.5C 212.167,173.5 209.833,173.5 207.5,173.5C 206.399,162.2 206.233,150.867 207,139.5C 209.588,136.8 212.421,134.467 215.5,132.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f5f3f1"
          d="M 92.5,140.5 C 96.0781,140.549 99.4115,141.215 102.5,142.5C 102.38,144.199 102.046,145.865 101.5,147.5C 101.942,148.739 102.609,149.739 103.5,150.5C 101.393,152.652 98.7262,153.652 95.5,153.5C 89.8334,153.22 86.5001,150.22 85.5,144.5C 87.3999,142.384 89.7332,141.051 92.5,140.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f5f3f2"
          d="M 154.5,140.5 C 154.338,142.527 154.505,144.527 155,146.5C 160.743,148.703 163.243,146.703 162.5,140.5C 164.5,140.5 166.5,140.5 168.5,140.5C 168.656,143.066 168.322,145.566 167.5,148C 166.218,149.911 164.385,150.744 162,150.5C 156.394,150.322 151.228,148.822 146.5,146C 148.517,143.139 151.183,141.305 154.5,140.5 Z M 154.5,140.5 C 157.167,140.5 159.833,140.5 162.5,140.5C 163.243,146.703 160.743,148.703 155,146.5C 154.505,144.527 154.338,142.527 154.5,140.5 Z"
        />
      </g>
      <g>
        <path
          fill={color == "black" ? light : "#111111"}
          d="M 372.5,179.5 C 426.168,179.333 479.834,179.5 533.5,180C 533.166,191.13 531.832,202.297 529.5,213.5C 520.833,213.5 512.167,213.5 503.5,213.5C 502.418,223.157 501.418,232.824 500.5,242.5C 506.176,242.666 511.843,242.499 517.5,242C 519.869,238.774 522.869,236.608 526.5,235.5C 525.807,246.189 524.14,256.689 521.5,267C 502.167,267.667 482.833,267.667 463.5,267C 462.892,265.769 462.559,264.436 462.5,263C 463.763,250.477 465.096,237.977 466.5,225.5C 451.163,225.333 435.83,225.5 420.5,226C 416.034,239.73 411.368,253.397 406.5,267C 394.505,267.5 382.505,267.667 370.5,267.5C 376.475,249.599 382.142,231.599 387.5,213.5C 380.833,213.5 374.167,213.5 367.5,213.5C 368.305,202.006 369.972,190.673 372.5,179.5 Z M 408.5,198.5 C 436.502,198.333 464.502,198.5 492.5,199C 493.711,200.113 493.711,201.28 492.5,202.5C 464.175,203.5 435.841,203.833 407.5,203.5C 407.366,201.708 407.699,200.042 408.5,198.5 Z"
        />
      </g>
      <g>
        <path
          fill="#e2b191"
          d="M 121.5,196.5 C 128.508,196.334 135.508,196.5 142.5,197C 146.972,198.736 150.972,201.236 154.5,204.5C 152.78,205.934 150.78,206.767 148.5,207C 134.155,207.833 119.822,207.666 105.5,206.5C 105.98,204.051 106.98,201.885 108.5,200C 112.848,198.417 117.182,197.251 121.5,196.5 Z"
        />
      </g>
      <g>
        <path
          fill="#6e5951"
          d="M 120.5,214.5 C 128.65,213.507 136.983,213.174 145.5,213.5C 145.819,218.363 145.486,223.03 144.5,227.5C 144.5,223.167 144.5,218.833 144.5,214.5C 136.5,214.5 128.5,214.5 120.5,214.5 Z"
        />
      </g>
      <g>
        <path
          fill="#e2b291"
          d="M 120.5,214.5 C 128.5,214.5 136.5,214.5 144.5,214.5C 144.5,218.833 144.5,223.167 144.5,227.5C 135.178,228.35 125.845,228.517 116.5,228C 114.353,224.026 114.02,219.86 115.5,215.5C 117.071,214.691 118.738,214.357 120.5,214.5 Z"
        />
      </g>
    </svg>
  );
};

export const Live = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="8688"
    width="100%"
    height="100%"
  >
    <path
      d="M512 0C229.376 0 0 229.376 0 512s229.376 512 512 512 512-229.376 512-512S794.624 0 512 0z m64.512 238.592c9.728-9.728 25.088-9.728 34.816 0 9.728 9.728 9.728 25.088 0 34.816-4.608 4.608-7.168 11.264-7.168 17.408 0 6.144 2.048 12.288 7.168 17.408l0.512 0.512 0.512 0.512c14.336 13.824 21.504 32.768 21.504 52.224 0 19.456-7.68 37.888-21.504 52.224-4.608 4.608-11.264 7.168-17.408 7.168-6.144 0-12.288-2.048-17.408-7.168-9.728-9.728-9.728-25.088 0-34.816 4.608-4.608 7.168-10.752 7.168-17.408 0-6.656-2.56-12.8-7.68-17.408-14.336-14.336-22.528-33.28-22.528-52.224s8.192-39.424 22.016-53.248z m-132.608 0c9.728-9.728 25.088-9.728 34.816 0 9.728 9.728 9.728 25.088 0 34.816-4.608 4.608-7.168 11.264-7.168 17.408s2.048 12.288 7.168 17.408l0.512 0.512 0.512 0.512c13.824 14.336 21.504 32.768 21.504 52.224 0 19.456-7.68 37.888-21.504 52.224-4.608 4.608-11.264 7.168-17.408 7.168-6.144 0-12.288-2.048-17.408-7.168-9.728-9.728-9.728-25.088 0-34.816 4.608-4.608 7.168-10.752 7.168-17.408 0-6.656-2.56-12.8-7.68-17.408-14.336-14.336-22.528-33.28-22.528-52.224s8.192-39.424 22.016-53.248z m-132.608 0c9.728-9.728 25.088-9.728 34.816 0 9.728 9.728 9.728 25.088 0 34.816-4.608 4.608-7.168 11.264-7.168 17.408s2.048 12.288 7.168 17.408l0.512 0.512 0.512 0.512c13.312 13.312 21.504 31.744 21.504 51.2s-7.68 37.888-21.504 52.224c-4.608 4.608-11.264 7.168-17.408 7.168-6.144 0-12.288-2.048-17.408-7.168-9.728-9.728-9.728-25.088 0-34.816 4.608-4.608 7.168-10.752 7.168-17.408 0-6.656-2.56-12.8-7.68-17.408-14.336-14.336-22.528-33.28-22.528-52.224s7.68-38.4 22.016-52.224z m444.928 438.272c-20.48 0-38.4-6.656-53.248-17.92-12.8 33.792-32.256 65.024-58.88 91.648-47.616 47.616-111.616 74.24-179.2 74.24s-131.072-26.112-179.2-74.24c-47.616-47.616-74.24-111.616-74.24-179.2V495.104c0-13.824 10.752-25.088 25.088-25.088h456.192c13.824 0 25.088 10.752 25.088 25.088v12.8c11.776-5.12 24.064-8.704 37.888-8.704 48.64 0 88.576 39.936 88.576 88.576s-39.424 89.088-88.064 89.088z"
      p-id="8689"
    ></path>
    <path
      d="M765.952 546.304c-23.04 0-41.472 18.432-41.472 41.472s18.432 41.472 41.472 41.472c22.528 0 41.472-18.432 41.472-41.472s-18.432-41.472-41.472-41.472z"
      p-id="8690"
    ></path>
  </svg>
);

export const Work = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="25470"
    width="100%"
    height="100%"
  >
    <path
      d="M512 0C228.963 0 1.111 230.398 1.111 512S228.968 1024 512 1024s510.889-230.398 510.889-512S792.989 0 512 0zM386.317 208.898c4.083-8.706 14.82-12.806 29.632-12.806h198.225c10.724 0 21.457 4.096 25.544 12.806 4.087 10.75 4.087 21.504-2.04 32.254l-47.001 66.054c-10.729 12.798-29.636 21.504-44.456 21.504H482.36c-14.824 0-34.234-8.706-44.963-21.504L390.4 241.152c-10.218-10.75-10.218-21.504-4.083-32.254zM645.85 710.651L537.034 815.1c-4.088 4.096-14.816 10.75-25.545 10.75s-19.418-2.047-25.544-10.75L377.12 710.652c-10.729-10.75-14.82-29.687-10.729-45.052l85.315-275.454c4.091-14.846 23.505-29.692 42.408-29.692h36.268c19.419 0 36.274 12.802 42.409 29.692l85.318 275.454c3.065 16.897-1.537 34.297-12.261 45.052z m0 0"
      fill="#2C2C2C"
      p-id="25471"
    ></path>
  </svg>
);

export const Favorite = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="45658"
    data-spm-anchor-id="a313x.search_index.0.i11.7bc93a81jjE3SV"
    width="100%"
    height="100%"
  >
    <path
      d="M512 0C229.248 0 0 229.248 0 512s229.248 512 512 512 512-229.248 512-512S794.752 0 512 0zM783.36 523.008c-6.08 19.136-13.696 36.864-22.656 53.504-9.024 16.512-18.88 31.744-29.888 45.824-10.752 14.08-21.632 26.752-32.64 38.08-16.832 17.216-34.176 33.28-52.416 48-18.24 14.848-35.712 27.712-52.16 38.4-16.64 10.688-31.616 19.264-45.248 25.472-13.568 6.208-24.192 9.344-31.68 9.344-8.448 0.512-19.136-2.496-32-8.768-13.056-6.4-27.392-14.72-42.816-25.472-15.552-10.688-31.872-23.296-49.088-38.016-17.216-14.656-34.112-30.208-50.88-46.528-10.944-10.496-22.656-22.656-35.2-36.416C294.144 612.544 282.304 597.44 271.104 580.864 260.032 564.288 250.496 546.368 242.56 527.104 234.624 507.84 230.272 487.296 229.312 465.472c-0.768-21.76 1.472-42.24 6.976-61.248 5.376-19.072 13.568-36.096 24.512-51.264 10.88-15.04 24.32-27.776 40.192-38.016 15.936-10.176 34.048-17.472 54.144-21.632C365.056 291.264 375.104 290.944 385.28 292.416 395.2 293.888 405.12 296.384 414.784 299.904c9.536 3.648 18.88 8.128 27.584 13.632 8.96 5.376 17.216 11.008 25.216 16.96 18.432 13.824 35.968 30.336 52.8 49.536 13.76-17.152 28.48-32.64 44.032-46.4 13.44-11.392 28.608-21.632 45.504-30.784 17.024-9.28 34.624-13.248 53.12-12.032 25.984 2.176 47.616 8.384 65.024 18.624 17.472 10.24 31.232 23.232 41.536 38.848 10.24 15.808 17.216 33.6 21.056 53.504 3.776 19.904 5.056 40.384 3.776 61.312C793.088 483.968 789.376 504 783.36 523.008z"
      fill="#050101"
      p-id="45659"
      data-spm-anchor-id="a313x.search_index.0.i10.7bc93a81jjE3SV"
    ></path>
  </svg>
);

export const Nodata = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <svg
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="7145"
        width="70"
        height="70"
      >
        <path
          d="M892.509 959.254v-884.364h-768v884.364h768M950.691 1017.436h-884.364v-1000.727h884.364v1000.727z"
          p-id="7146"
          fill="#707070"
        ></path>
        <path
          d="M97.745 284.345v-116.364h537.6v116.364h-537.6z"
          p-id="7147"
          fill="#707070"
        ></path>
        <path
          d="M741.236 284.345v-116.364h186.182v116.364h-186.182z"
          p-id="7148"
          fill="#707070"
        ></path>
        <path
          d="M205.964 447.254v-58.182h162.909v58.182h-162.909z"
          p-id="7149"
          fill="#707070"
        ></path>
        <path
          d="M428.218 447.254v-58.182h382.836v58.182h-382.836z"
          p-id="7150"
          fill="#707070"
        ></path>
        <path
          d="M205.964 563.618v-58.182h162.909v58.182h-162.909z"
          p-id="7151"
          fill="#707070"
        ></path>
        <path
          d="M428.218 563.618v-58.182h382.836v58.182h-382.836z"
          p-id="7152"
          fill="#707070"
        ></path>
        <path
          d="M205.964 726.527v-58.182h162.909v58.182h-162.909z"
          p-id="7153"
          fill="#707070"
        ></path>
        <path
          d="M428.218 726.527v-58.182h382.836v58.182h-382.836z"
          p-id="7154"
          fill="#707070"
        ></path>
        <path
          d="M205.964 842.89v-58.182h162.909v58.182h-162.909z"
          p-id="7155"
          fill="#707070"
        ></path>
        <path
          d="M428.218 842.89v-58.182h382.836v58.182h-382.836z"
          p-id="7156"
          fill="#707070"
        ></path>
      </svg>

      <div className="mt-5">暂无记录</div>
    </div>
  );
};

export const Refresh = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    className="h-5 w-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
    />
  </svg>
);

export const WeChat = ({ className }: CommProps) => (
  <svg
    className={className}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="14748"
    width="20"
    height="20"
  >
    <path
      d="M690.1 377.4c5.9 0 11.8 0.2 17.6 0.5-24.4-128.7-158.3-227.1-319.9-227.1C209 150.8 64 271.4 64 420.2c0 81.1 43.6 154.2 111.9 203.6 5.5 3.9 9.1 10.3 9.1 17.6 0 2.4-0.5 4.6-1.1 6.9-5.5 20.3-14.2 52.8-14.6 54.3-0.7 2.6-1.7 5.2-1.7 7.9 0 5.9 4.8 10.8 10.8 10.8 2.3 0 4.2-0.9 6.2-2l70.9-40.9c5.3-3.1 11-5 17.2-5 3.2 0 6.4 0.5 9.5 1.4 33.1 9.5 68.8 14.8 105.7 14.8 6 0 11.9-0.1 17.8-0.4-7.1-21-10.9-43.1-10.9-66 0-135.8 132.2-245.8 295.3-245.8z m-194.3-86.5c23.8 0 43.2 19.3 43.2 43.1s-19.3 43.1-43.2 43.1c-23.8 0-43.2-19.3-43.2-43.1s19.4-43.1 43.2-43.1z m-215.9 86.2c-23.8 0-43.2-19.3-43.2-43.1s19.3-43.1 43.2-43.1 43.2 19.3 43.2 43.1-19.4 43.1-43.2 43.1z"
      p-id="14749"
      fill="#ffffff"
    ></path>
    <path
      d="M866.7 792.7c56.9-41.2 93.2-102 93.2-169.7 0-124-120.8-224.5-269.9-224.5-149 0-269.9 100.5-269.9 224.5S540.9 847.5 690 847.5c30.8 0 60.6-4.4 88.1-12.3 2.6-0.8 5.2-1.2 7.9-1.2 5.2 0 9.9 1.6 14.3 4.1l59.1 34c1.7 1 3.3 1.7 5.2 1.7 2.4 0 4.7-0.9 6.4-2.6 1.7-1.7 2.6-4 2.6-6.4 0-2.2-0.9-4.4-1.4-6.6-0.3-1.2-7.6-28.3-12.2-45.3-0.5-1.9-0.9-3.8-0.9-5.7 0.1-5.9 3.1-11.2 7.6-14.5zM600.2 587.2c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c0 19.8-16.2 35.9-36 35.9z m179.9 0c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c-0.1 19.8-16.2 35.9-36 35.9z"
      p-id="14750"
      fill="#ffffff"
    ></path>
  </svg>
);


export const LinkSvg = ({ className }: CommProps) => <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4652" width="24" height="24"><path d="M768 768H256V256h298.666667V170.666667H213.333333a42.666667 42.666667 0 0 0-42.666666 42.666666v597.333334a42.666667 42.666667 0 0 0 42.666666 42.666666h597.333334a42.666667 42.666667 0 0 0 42.666666-42.666666v-341.333334h-85.333333v298.666667z" fill="" p-id="4653"></path><path d="M745.173333 218.496l-263.338666 263.338667 60.330666 60.330666 263.338667-263.338666 90.453333 90.496L896 128h-241.365333z" fill="" p-id="4654"></path></svg>


export const Drag = ({ className }: CommProps) => <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2582" width="200" height="200"><path d="M934.4 460.8l-115.2-117.333333c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333l55.466666 55.466667H569.6v-234.666667l55.466667 55.466667c17.066667 17.066667 44.8 17.066667 59.733333 0 17.066667-17.066667 17.066667-44.8 0-59.733334l-117.333333-115.2c-21.333333-21.333333-57.6-21.333333-81.066667 0l-117.333333 117.333334c-17.066667 17.066667-17.066667 44.8 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8 10.666667 0 21.333333-4.266667 29.866667-12.8l55.466667-55.466667v232.533334H243.2l55.466667-55.466667c17.066667-17.066667 17.066667-44.8 0-59.733333-17.066667-17.066667-44.8-17.066667-59.733334 0l-115.2 117.333333c-21.333333 21.333333-21.333333 57.6 0 81.066667l117.333334 117.333333c8.533333 8.533333 19.2 12.8 29.866666 12.8 10.666667 0 21.333333-4.266667 29.866667-12.8 17.066667-17.066667 17.066667-42.666667 0-59.733333l-55.466667-55.466667h241.066667v241.066667l-55.466667-55.466667c-17.066667-17.066667-44.8-17.066667-59.733333 0-17.066667 17.066667-17.066667 44.8 0 59.733333l117.333333 117.333334c10.666667 10.666667 25.6 17.066667 40.533334 17.066666s29.866667-6.4 40.533333-17.066666l117.333333-115.2c17.066667-17.066667 17.066667-44.8 0-59.733334-17.066667-17.066667-44.8-17.066667-59.733333 0l-55.466667 55.466667V544H810.666667l-55.466667 55.466667c-17.066667 17.066667-17.066667 44.8 0 59.733333 8.533333 8.533333 19.2 12.8 29.866667 12.8 10.666667 0 21.333333-4.266667 29.866666-12.8l117.333334-117.333333c23.466667-21.333333 23.466667-57.6 2.133333-81.066667z" p-id="2583"></path></svg>

export const FullScreen = ({ className }: CommProps) => <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6312" width="200" height="200"><path d="M298.666666 597.333334 213.333334 597.333334 213.333334 810.666664 426.666666 810.666664 426.666666 725.333334 298.666666 725.333334 298.666666 597.333334 298.666666 597.333334ZM213.333334 426.666666 298.666666 426.666666 298.666666 298.666666 426.666666 298.666666 426.666666 213.333334 213.333334 213.333334 213.333334 426.666666 213.333334 426.666666ZM725.333334 725.333334 597.333334 725.333334 597.333334 810.666664 810.666664 810.666664 810.666664 597.333334 725.333334 597.333334 725.333334 725.333334 725.333334 725.333334ZM597.333334 213.333334 597.333334 298.666666 725.333334 298.666666 725.333334 426.666666 810.666664 426.666666 810.666664 213.333334 597.333334 213.333334 597.333334 213.333334Z" fill="currentColor" p-id="6313"></path></svg>

export const Menu = ({ className }: CommProps) => <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7564" width="200" height="200"><path d="M512 1024C229.232 1024 0 794.768 0 512 0 229.232 229.232 0 512 0c282.768 0 512 229.232 512 512 0 282.768-229.232 512-512 512zM302 320a30 30 0 0 0 0 60h420a30 30 0 0 0 0-60h-420z m0 160a30 30 0 0 0 0 60h420a30 30 0 0 0 0-60h-420z m0 160a30 30 0 0 0 0 60h420a30 30 0 0 0 0-60h-420z" p-id="7565"></path></svg>

export const Previous = ({ className }: CommProps) => <svg className={className}  xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"><g fill="none"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="currentColor" d="m20.43 5.865l.078.699l.072.767l.036.448l.051.75l.03.55l.038.894l.019.641l.012.675l.004.707l-.004.707l-.012.675l-.019.641l-.024.606l-.028.569l-.05.78l-.035.47l-.09.986l-.079.698a1.332 1.332 0 0 1-1.844 1.065l-.49-.213l-.846-.386l-.62-.298l-.458-.226l-.748-.381l-.538-.283l-.566-.306l-.594-.329l-.619-.353l-.615-.36l-.582-.349l-.809-.5l-.73-.47l-.443-.292l-.406-.274l-.54-.373l-.587-.42l-.43-.319a1.332 1.332 0 0 1 .002-2.13l.325-.242l.422-.306l.517-.363l.607-.414l.694-.458l.51-.327l.546-.342l.581-.355l.617-.366l.32-.186q.312-.18.613-.349l.588-.326l.563-.304l.793-.414l.725-.365l.442-.215l.597-.283l.802-.362l.355-.154a1.332 1.332 0 0 1 1.846 1.065ZM6 5a1 1 0 0 1 .993.883L7 6v12a1 1 0 0 1-.883.993L6 19H5a1 1 0 0 1-.993-.883L4 18V6a1 1 0 0 1 .883-.993L5 5z"></path></g></svg>

export const Pause = ({ className }: CommProps) => <svg className={className} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2m-1 14H9V8h2zm4 0h-2V8h2z"></path></svg>

export const RefreshNew = ({ className }: CommProps) => <svg className={className} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"><path fill="currentColor" d="M5.463 4.433A9.96 9.96 0 0 1 12 2c5.523 0 10 4.477 10 10c0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228zm13.074 15.134A9.96 9.96 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772z"></path></svg>

export const Play = ({ className }: CommProps) => <svg  className={className} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10m-1.306-6.154l4.72-2.787c.781-.462.781-1.656 0-2.118l-4.72-2.787C9.934 7.706 9 8.29 9 9.214v5.573c0 .923.934 1.507 1.694 1.059" clipRule="evenodd"></path></svg>